import React, {
  memo,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import {
  Bar,
  CartesianGrid,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  classNames,
  isNotNullAndNotUndefined,
  isNullOrUndefined,
} from "../../src/formatting";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { RoiMetricsQuery, useRoiMetricsQuery } from "@comulate/graphql-types";
import Skeleton from "react-loading-skeleton";
import {
  UserActivityIntervalsQuery,
  useUserActivityIntervalsQuery,
} from "@comulate/graphql-types";
import { DateTime } from "luxon";
import * as _ from "lodash";
import { useCallback, useState } from "react";
import { defaultStyles, useTooltipInPortal } from "@visx/tooltip";
import { useUserAvatarUrls } from "../../hooks/useUserAvatarUrls";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/Button";
import Tippy from "@tippyjs/react";
import Menu, { EllipsisMenu } from "../../components/Menu";
import { Input } from "../../core/Input";
import { localPoint } from "@visx/event";

/** ACTIVITIES CONSTANTS  */

const MIN_HEATMAP_WIDTH = 76;
const MAX_HEATMAP_WIDTH = 120;
const MIN_HEATMAP_GAP = 12;
const HEATMAP_HEIGHT = 440;

const CELL_SPACING_X = 2;
const CELL_SPACING_Y = 3;

const INTERVAL_LENGTH_MINUTES = 8;
const START_DISPLAY_HOUR = 7; // Only show activities after 7am
const END_DISPLAY_HOUR = 19; // Only show activities before 7pm

type UserMetricsRow = RoiMetricsQuery["roiMetrics"]["userMetrics"][number] & {
  userId: string;
  minScore: number;
  maxScore: number;
  scoreDiff: number;
  chartPadding: number;
};

const ROIMetrics = ({
  selectedPeriodId,
}: {
  selectedPeriodId: string | null;
}) => {
  const [expectedHoursPerWeek, setExpectedHoursPerWeek] = useState(30);

  const { data } = useRoiMetricsQuery({
    variables: {
      periodId: selectedPeriodId as string,
      expectedHoursPerWeek: expectedHoursPerWeek,
    },
    skip: isNullOrUndefined(selectedPeriodId),
  });

  const userMetrics = (data?.roiMetrics.userMetrics || []).filter(
    ({ outputScore }) => outputScore > 0
  );

  const minScore = Math.min(
    ...userMetrics.map((item) =>
      Math.min(item.outputScore, item.targetOutputScore)
    )
  );
  const maxScore = Math.max(
    ...userMetrics.map((item) =>
      Math.max(item.outputScore, item.targetOutputScore)
    )
  );
  const maxScoreDiff = Math.max(
    ...userMetrics.map((item) =>
      Math.abs(item.targetOutputScore - item.outputScore)
    )
  );

  // Add some buffer to min/max chart ranges so there's space for
  // numeric values above/below the bars
  const chartPadding = maxScoreDiff * 0.3;

  const metricsSummary = data?.roiMetrics.summary;
  const userMetricsChartData =
    data?.roiMetrics.userMetrics.map((item) => ({
      ...item,
      userId: item.user.id /** Used as x-axis index */,
      scoreDiff: Math.abs(item.targetOutputScore - item.outputScore),
      minScore,
      maxScore,
      chartPadding,
    })) || [];

  return (
    <div className="space-y-5 border-b">
      <div className="flex justify-between items-center">
        <div className="text-2xl leading-8 font-medium text-zinc-900">
          Workload and Process
        </div>
        <EllipsisMenu>
          <Menu.Group>
            <Menu.Item>
              <div
                style={{ width: 200 }}
                className="py-1 px-4 text-sm leading-4 font-medium flex justify-end items-center space-x-2 text-zinc-600"
              >
                <span>Hours/Week</span>
                <Input
                  type="number"
                  className="w-[64px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  value={expectedHoursPerWeek}
                  onChange={(event) =>
                    setExpectedHoursPerWeek(parseInt(event.target.value))
                  }
                  onClick={(event) => event.stopPropagation()}
                />
              </div>
            </Menu.Item>
          </Menu.Group>
        </EllipsisMenu>
      </div>
      <div className="w-full border-y divide-y">
        <div className="grid grid-cols-4 divide-x">
          <SummaryMetric
            title="Total Output"
            metric={
              metricsSummary
                ? Math.round(metricsSummary.totalOutputScore).toLocaleString()
                : null
            }
            className="pr-6"
          />
          <SummaryMetric
            title="Output per Day"
            metric={
              metricsSummary
                ? Math.round(
                    metricsSummary.avgDailyOutputScore
                  ).toLocaleString()
                : null
            }
            className="px-6"
          />
          <SummaryMetric
            title="Weekly Activity per Person"
            metric={
              metricsSummary
                ? `${metricsSummary.avgWeeklyActiveHours.toFixed(1)} hours`
                : null
            }
            className="px-6"
          />
          <SummaryMetric
            title="Optimized Workload"
            metric={
              metricsSummary
                ? `${Math.round(
                    metricsSummary.numEffectiveEmployees
                  ).toLocaleString()} people`
                : null
            }
            className="pl-6"
          />
        </div>
        <div className="flex divide-x w-full">
          <div className="w-1/2 pt-5 pb-9 pr-6">
            <div className="space-y-3">
              <div className="text-lg leading-6 font-medium text-zinc-900">
                Output
              </div>
              <div className="h-[246px]">
                {data ? (
                  userMetricsChartData.length ? (
                    <PersonChart
                      dataKey="outputScore"
                      data={userMetricsChartData}
                      barShape={SandBar}
                      tooltipContent={SandBarTooltip}
                      yMin={0}
                      yMax={
                        // Slightly scale the y-axis to ensure there is enough space
                        // on top for text for highest value
                        1.15 *
                        Math.max(...userMetrics.map((item) => item.outputScore))
                      }
                    />
                  ) : (
                    <EmptyResultsMessage />
                  )
                ) : (
                  <ChartLoader />
                )}
              </div>
            </div>
          </div>
          <div className="w-1/2 pt-5 pb-9 pl-6">
            <div className="space-y-3">
              <div className="text-lg leading-6 font-medium text-zinc-900">
                Workload Optimization
              </div>
              <div className="h-[246px]">
                {data ? (
                  userMetricsChartData.length ? (
                    <PersonChart
                      dataKey="scoreDiff"
                      data={userMetricsChartData}
                      yMin={minScore}
                      yMax={maxScore}
                      chartPadding={chartPadding}
                      barShape={ActivityBar}
                      tooltipContent={ActivityBarTooltip}
                    />
                  ) : (
                    <EmptyResultsMessage />
                  )
                ) : (
                  <ChartLoader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <UserActivitySection
          directBillUsers={userMetricsChartData.map(({ user }) => user)}
        />
      </div>
    </div>
  );
};

const SummaryMetric = ({
  title,
  metric,
  className,
}: {
  title: string;
  metric: string | null;
  className?: string;
}) => (
  <div className={classNames("py-5 space-y-1", className)}>
    <div className="text-base font-medium text-zinc-600">{title}</div>
    {metric ? (
      <div className="text-2xl font-normal text-zinc-800">{metric}</div>
    ) : (
      <Skeleton width={120} height={16} className="mt-4" />
    )}
  </div>
);

const EmptyResultsMessage = () => (
  <div className="px-10 py-20 w-max m-auto text-xs text-slate-600">
    There are no results for this time period.
  </div>
);

const ChartLoader = () => (
  <div className="flex justify-between items-end">
    {[200, 180, 170, 140, 140, 140, 120, 110, 80, 60].map((height, ind) => (
      <div key={ind}>
        <Skeleton width={30} height={height} />
        <Skeleton circle width={24} height={24} className="mt-4 mx-[3px]" />
      </div>
    ))}
  </div>
);

const AVATAR_SIZE = 24;

const AvatarAxisTick = ({ x, y, payload }: any) => {
  const { avatarUrlForUserId } = useUserAvatarUrls();

  return (
    <g transform={`translate(${x - AVATAR_SIZE / 2},${y})`}>
      <image
        href={avatarUrlForUserId(payload.value)}
        width="24"
        height="24"
        clipPath="inset(0% round 50%)"
      />
      <rect
        x={0}
        y={0}
        width={AVATAR_SIZE}
        height={AVATAR_SIZE}
        rx={AVATAR_SIZE / 2}
        ry={AVATAR_SIZE / 2}
        fill="none"
        className="stroke-slate-300"
        strokeWidth={1}
      />
    </g>
  );
};

const PersonChart = React.forwardRef<
  HTMLDivElement,
  {
    data: UserMetricsRow[];
    dataKey: string;
    yMin?: number;
    yMax?: number;
    chartPadding?: number;
    tooltipContent?: ({
      active,
      payload,
    }: {
      active: boolean;
      payload: any;
    }) => ReactNode;
    barShape: (props: any) => JSX.Element;
  }
>(
  (
    { barShape, tooltipContent, data = [], dataKey, chartPadding, yMax, yMin },
    forwardedRef
  ) => (
    <div ref={forwardedRef} className="h-full w-full">
      <ResponsiveContainer>
        <RechartsBarChart data={data}>
          <CartesianGrid
            vertical={false}
            className="stroke-zinc-100 stroke-1"
          />
          <YAxis
            hide
            allowDataOverflow
            domain={
              isNotNullAndNotUndefined(yMin) && isNotNullAndNotUndefined(yMax)
                ? [yMin - (chartPadding || 0), yMax + (chartPadding || 0)]
                : undefined
            }
          />
          <XAxis
            tick={(props) => <AvatarAxisTick {...props} />}
            tickLine={false}
            tickMargin={8}
            dataKey="userId"
            stroke="stroke-zinc-100"
            interval={0}
          />
          {tooltipContent && (
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              isAnimationActive={true}
              animationDuration={100}
              cursor={{ fill: "#d1d5db", opacity: "0.15" }}
              offset={20}
              position={{ y: 0 }}
              content={tooltipContent}
            />
          )}
          <Bar
            key={dataKey}
            name={dataKey}
            dataKey={dataKey}
            shape={barShape}
            width={30}
            barSize={30}
            type="linear"
          />
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  )
);

const ARROW_ICON_SIZE = 12;

const ActivityBar = ({
  payload,
  x,
  width,
  y,
  height,
}: {
  payload: UserMetricsRow;
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  const isPositive = payload.outputScore > payload.targetOutputScore;
  const ratio =
    (y + height) /
    (payload.maxScore - payload.minScore + 2 * payload.chartPadding);

  const sourceY =
    (payload.maxScore + payload.chartPadding - payload.outputScore) * ratio;
  const targetY =
    (payload.maxScore + payload.chartPadding - payload.targetOutputScore) *
    ratio;
  const scaledHeight = Math.abs(targetY - sourceY);

  return (
    <>
      <linearGradient id="green-gradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor="#EDFDEE" />
        <stop offset="100%" stopColor="#C9F8D1" />
      </linearGradient>
      <linearGradient id="red-gradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor="#FADBD0" />
        <stop offset="100%" stopColor="#FDEDE8" />
      </linearGradient>
      <text
        x={x + width / 2}
        y={isPositive ? sourceY - 8 : sourceY + 20}
        width={width}
        className="fill-zinc-800 text-xs font-medium"
        text-anchor="middle"
      >
        {Math.round(payload.outputScore)}
      </text>
      <text
        x={x + width / 2 - 2}
        y={isPositive ? targetY + 16 : targetY - 8}
        width={width}
        className={classNames(
          "text-xs font-medium",
          isPositive ? "fill-green-700" : "fill-red-700"
        )}
        text-anchor="middle"
      >
        {`${isPositive ? "-" : "+"}${Math.round(
          Math.abs(payload.outputScore - payload.targetOutputScore)
        )}`}
      </text>
      <g
        clipPath={
          isPositive
            ? "inset(0 round 0 0 4px 4px)"
            : "inset(0 round 4px 4px 0 0)"
        }
      >
        <rect
          x={x}
          y={isPositive ? sourceY : targetY}
          width={width}
          height={scaledHeight}
          fill={isPositive ? "url(#green-gradient)" : "url(#red-gradient)"}
          className="border-b border-blue-500"
        />
        <rect
          x={x}
          y={isPositive ? sourceY : targetY + scaledHeight}
          width={width}
          height={3}
          className={classNames(isPositive ? "fill-green-600" : "fill-red-600")}
        />
        <rect
          x={x}
          y={isPositive ? sourceY + scaledHeight : targetY}
          width={width}
          height={2}
          className={classNames(isPositive ? "fill-green-400" : "fill-red-400")}
        />
        {Math.abs(scaledHeight) > 1.5 * ARROW_ICON_SIZE &&
          (isPositive ? (
            <ArrowDownIcon
              x={x + width / 2 - ARROW_ICON_SIZE / 2}
              y={sourceY + scaledHeight / 2 - ARROW_ICON_SIZE / 2 + 2}
              width={ARROW_ICON_SIZE}
              height={ARROW_ICON_SIZE}
              className="text-green-600"
            />
          ) : (
            <ArrowUpIcon
              x={x + width / 2 - ARROW_ICON_SIZE / 2}
              y={targetY + scaledHeight / 2 - ARROW_ICON_SIZE / 2 + 2}
              width={ARROW_ICON_SIZE}
              height={ARROW_ICON_SIZE}
              className="text-red-600"
            />
          ))}
      </g>
    </>
  );
};

const ActivityBarTooltip = ({
  active,
  payload: payloadList,
}: {
  active: boolean;
  payload: { payload: UserMetricsRow }[];
}) => {
  const payload = payloadList.at(0)?.payload;

  if (!payload) return null;

  const isPositive = payload.outputScore > payload.targetOutputScore;

  return (
    <TremorTooltip
      active={active}
      user={payload.user}
      items={[
        {
          key: "Actual",
          color: isPositive ? "bg-green-600" : "bg-red-600",
          value: Math.round(payload.outputScore).toString(),
        },
        {
          key: "Optimized",
          color: isPositive ? "bg-green-400" : "bg-red-400",
          value: Math.round(payload.targetOutputScore).toString(),
        },
      ]}
    />
  );
};

const TremorTooltip = ({
  active,
  user,
  items,
}: {
  active: boolean;
  user: {
    id: string;
    fullName: string;
  };
  items: { key: string; color: string; value: string }[];
}) => {
  const { avatarUrlForUserId } = useUserAvatarUrls();

  if (!active) return null;

  return (
    <div
      style={{ width: 176 }}
      className="rounded-md border text-sm shadow-md border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-950"
    >
      <div className="border-b border-inherit px-4 py-2 flex justify-between items-center">
        <span className="font-medium text-gray-900 truncate">
          {user.fullName}
        </span>
        <img
          src={avatarUrlForUserId(user.id)}
          className="size-6 rounded-full border"
          style={{ borderColor: "#3F3F4633" }}
        />
      </div>
      <div className="space-y-1 px-4 py-2">
        {items.map((item, itemInd) => (
          <div
            key={itemInd}
            className="flex items-center justify-between space-x-8 w-full"
          >
            <div className="flex items-center space-x-2">
              <span
                aria-hidden="true"
                className={classNames("size-2 shrink-0 rounded-sm", item.color)}
              />
              <p className="whitespace-nowrap text-right text-gray-700">
                {item.key}
              </p>
            </div>
            <p className="whitespace-nowrap text-right font-medium tabular-nums text-gray-900">
              {item.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const SandBar = ({
  payload,
  x,
  width,
  y,
  height,
}: {
  payload: UserMetricsRow;
  x: number;
  y: number;
  width: number;
  height: number;
}) => (
  <>
    <text
      x={x + width / 2}
      y={y - 8}
      width={width}
      className="fill-zinc-800 text-xs font-medium"
      text-anchor="middle"
    >
      {Math.round(payload.outputScore)}
    </text>
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="fill-sand-200"
      clipPath="inset(0 round 4px 4px 0 0)"
    />
  </>
);

const SandBarTooltip = ({
  active,
  payload: payloadList,
}: {
  active: boolean;
  payload: { payload: UserMetricsRow }[];
}) => {
  const payload = payloadList.at(0)?.payload;

  if (!payload) return null;

  return (
    <TremorTooltip
      active={active}
      user={payload.user}
      items={[
        {
          key: "Output",
          color: "bg-sand-200",
          value: Math.round(payload.outputScore).toString(),
        },
      ]}
    />
  );
};

const UserActivitySection = ({
  directBillUsers,
}: {
  directBillUsers?: { id: string }[];
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const now = useMemo(() => DateTime.now(), []);

  const [startRange, setStartRange] = useState(
    now.startOf("week").minus({ days: 1, weeks: 1 })
  );

  const endRange = useMemo(() => startRange.plus({ weeks: 1 }), [startRange]);

  const { data } = useUserActivityIntervalsQuery({
    variables: {
      startRange: startRange.toISO(),
      endRange: startRange.plus({ weeks: 1 }).toISO(),
    },
  });

  const userActivityIntervalsByUser = useMemo(
    () =>
      data && directBillUsers
        ? _.sortBy(
            Object.entries(
              _.groupBy(
                data?.userActivityIntervals,
                (interval) => interval.user.id
              )
            ).filter(([userId]) =>
              directBillUsers.some(({ id }) => id === userId)
            ),
            ([userId]) => directBillUsers.findIndex(({ id }) => id === userId)
          )
        : null,
    [data, directBillUsers]
  );

  const [width, setWidth] = useState<number | null>(null);

  const updateHeatmapWidth = useCallback(
    () =>
      setWidth(
        userActivityIntervalsByUser && containerRef.current
          ? Math.max(
              MIN_HEATMAP_WIDTH,
              Math.min(
                MAX_HEATMAP_WIDTH,
                (containerRef.current.getBoundingClientRect().width -
                  (userActivityIntervalsByUser.length - 1) * MIN_HEATMAP_GAP) /
                  userActivityIntervalsByUser.length
              )
            )
          : null
      ),
    [userActivityIntervalsByUser]
  );

  useLayoutEffect(updateHeatmapWidth, [updateHeatmapWidth]);

  useEffect(() => {
    window.addEventListener("resize", updateHeatmapWidth);

    return () => window.removeEventListener("resize", updateHeatmapWidth);
  }, [updateHeatmapWidth]);

  return (
    <div ref={containerRef} className="space-y-4">
      <div className="flex items-center justify-between">
        <span className="text-lg leading-6 font-medium text-zinc-900">
          Activity Periods
        </span>
        <div className="flex space-x-2 items-center">
          <Button
            colorScheme="inline"
            className="!p-1.5"
            onClick={() => setStartRange(startRange.minus({ weeks: 1 }))}
          >
            <ArrowLeftIcon className="size-4 text-zinc-500" />
          </Button>
          <div className="flex space-x-2 items-center justify-center w-[132px]">
            <span className="font-medium text-base text-zinc-700">
              {startRange.toFormat("LLL d")}
            </span>
            <span className="font-medium text-base text-zinc-500">–</span>
            <span className="font-medium text-base text-zinc-700">
              {endRange.toFormat("LLL d")}
            </span>
          </div>
          <Button
            colorScheme="inline"
            className="!p-1.5"
            disabled={endRange.plus({ weeks: 1 }) > now}
            onClick={() => setStartRange(startRange.plus({ weeks: 1 }))}
          >
            <ArrowRightIcon className="size-4 text-zinc-500" />
          </Button>
        </div>
      </div>
      <div className="w-full flex justify-around space-x-2 overflow-auto">
        {data ? (
          userActivityIntervalsByUser && width ? (
            userActivityIntervalsByUser.map(([, userGroups], groupIndex) => (
              <UserActivityIntervalGroup
                key={groupIndex}
                userGroups={_.sortBy(userGroups, (group) =>
                  DateTime.fromISO(group.intervalStart).toMillis()
                )}
                width={width}
              />
            ))
          ) : (
            <EmptyResultsMessage />
          )
        ) : (
          <UserActivitySectionLoader width={width || 100} />
        )}
      </div>
    </div>
  );
};

const UserActivitySectionLoader = ({ width }: { width: number }) =>
  _.range(6).map((ind) => (
    <Skeleton key={ind} width={width} height={HEATMAP_HEIGHT} />
  ));

type UserActivityIntervalMetrics =
  UserActivityIntervalsQuery["userActivityIntervals"][number];

const UserActivityIntervalGroup = memo(
  ({
    width,
    userGroups,
  }: {
    width: number;
    userGroups: Omit<UserActivityIntervalMetrics, "userId">[];
  }) => {
    const { containerRef, TooltipInPortal } = useTooltipInPortal({
      // use TooltipWithBounds
      detectBounds: true,
      // when tooltip containers are scrolled, this will correctly update the Tooltip position
      scroll: true,
    });

    const [tooltipData, setTooltipData] = useState<{
      top: number;
      left: number;
      groupIndex: number;
      filteredIntervalIndex: number;
      active: boolean;
      overflow: boolean;
      weekday: number;
    } | null>(null);

    const numDays = 5;
    const numIntervals =
      (60 / INTERVAL_LENGTH_MINUTES) * (END_DISPLAY_HOUR - START_DISPLAY_HOUR);
    const cellWidth =
      (width - (userGroups.length - 1) * CELL_SPACING_X) / userGroups.length;
    const cellHeight =
      (HEATMAP_HEIGHT - (numIntervals - 1) * CELL_SPACING_Y) / numIntervals;

    const innerWidth = width - 2 * cellWidth;

    const handleActiveMouseMove = useCallback(
      (event, columnIndex, rowIndex, overflow, active, weekday) => {
        const coords = localPoint(event.target.ownerSVGElement, event);

        if (!coords) return;

        setTooltipData({
          left: coords.x,
          top: coords.y,
          groupIndex: columnIndex,
          filteredIntervalIndex: rowIndex,
          overflow,
          active,
          weekday,
        });
      },
      []
    );

    const handleInactiveMouseMove = useCallback(
      (event) => {
        const coords = localPoint(event.target.ownerSVGElement, event);

        if (!coords) return;

        const day = Math.floor(
          (event.nativeEvent.offsetX / innerWidth) * numDays
        );
        const interval = Math.floor(
          (event.nativeEvent.offsetY / HEATMAP_HEIGHT) * numIntervals
        );

        setTooltipData({
          left: coords.x,
          top: coords.y,
          groupIndex: day,
          filteredIntervalIndex: interval,
          active: false,
          overflow: false,
          weekday: 1,
        });
      },
      [innerWidth, numIntervals]
    );

    return (
      <div className="relative flex flex-col space-y-2.5 shrink-0">
        <div style={{ width, height: HEATMAP_HEIGHT }}>
          <svg
            width="100%"
            height="100%"
            onMouseLeave={() => setTooltipData(null)}
            ref={containerRef}
          >
            <rect
              x={cellWidth}
              y={0}
              width={innerWidth}
              height={HEATMAP_HEIGHT}
              className="fill-zinc-200"
              onMouseMove={handleInactiveMouseMove}
            />
            {_.range(numIntervals).map((rowIndex) => (
              <rect
                x={0}
                y={rowIndex * (cellHeight + CELL_SPACING_Y) - CELL_SPACING_Y}
                width="100%"
                height={CELL_SPACING_Y}
                className="fill-white"
              />
            ))}
            {userGroups.map((group, columnIndex) => {
              let overflowStartIntervals = 0;
              let overflowEndIntervals = 0;

              const filteredGroups = group.intervalActiveStatuses.filter(
                (active, rowIndex) => {
                  const { hour } = DateTime.fromISO(group.intervalStart, {
                    zone: "utc",
                  }).plus({ minutes: INTERVAL_LENGTH_MINUTES * rowIndex });

                  if (active && hour < START_DISPLAY_HOUR) {
                    overflowStartIntervals += 1;
                  }
                  if (active && hour >= END_DISPLAY_HOUR) {
                    overflowEndIntervals += 1;
                  }

                  return hour >= START_DISPLAY_HOUR && hour < END_DISPLAY_HOUR;
                }
              );

              return (
                <>
                  {columnIndex !== 0 && (
                    <rect
                      x={
                        columnIndex * (cellWidth + CELL_SPACING_X) -
                        CELL_SPACING_X
                      }
                      y={0}
                      width={CELL_SPACING_X}
                      height="100%"
                      className="fill-white"
                    />
                  )}
                  {filteredGroups.map((active, rowIndex) => {
                    const { weekday } = DateTime.fromISO(group.intervalStart, {
                      zone: "utc",
                    });

                    let overflow = false;
                    if (!active) {
                      if (overflowStartIntervals > 0) {
                        overflow = true;
                        overflowStartIntervals -= 1;
                      } else if (overflowEndIntervals > 0) {
                        const remainingNonActiveIntervals = filteredGroups
                          .slice(rowIndex)
                          .filter((active) => !active).length;

                        if (
                          remainingNonActiveIntervals === overflowEndIntervals
                        ) {
                          overflow = true;
                          overflowEndIntervals -= 1;
                        }
                      }
                    }

                    return active || overflow ? (
                      <HoverRect
                        // eslint-disable-next-line tailwindcss/no-custom-classname
                        className="fill-sand-300"
                        rowIndex={rowIndex}
                        columnIndex={columnIndex}
                        cellWidth={cellWidth}
                        cellHeight={cellHeight}
                        onMouseMove={(event) => {
                          event.stopPropagation();

                          handleActiveMouseMove(
                            event,
                            columnIndex,
                            rowIndex,
                            overflow,
                            active,
                            weekday
                          );
                        }}
                        weekday={weekday}
                      />
                    ) : null;
                  })}
                </>
              );
            })}
            {tooltipData && (
              <HoverRect
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className={
                  tooltipData.active || tooltipData.overflow
                    ? "fill-sand-400"
                    : "fill-zinc-400"
                }
                rowIndex={tooltipData.filteredIntervalIndex}
                columnIndex={tooltipData.groupIndex}
                cellWidth={cellWidth}
                cellHeight={cellHeight}
                weekday={tooltipData.weekday}
              />
            )}
          </svg>
        </div>
        {tooltipData && (
          <TooltipInPortal
            top={tooltipData.top}
            left={tooltipData.left}
            style={{
              ...defaultStyles,
              padding: 0,
            }}
            className="!rounded-md border text-sm shadow-md border-gray-200 bg-white"
          >
            <ActivitiesTooltipContent
              group={userGroups[tooltipData.groupIndex]}
              filteredIntervalIndex={tooltipData.filteredIntervalIndex}
              overflow={tooltipData.overflow}
            />
          </TooltipInPortal>
        )}
        <div className="w-full flex justify-center">
          <ChartUserAvatar user={userGroups[0].user} />
        </div>
      </div>
    );
  }
);

const ChartUserAvatar = ({
  user: { id, fullName },
}: {
  user: {
    id: string;
    fullName: string;
  };
}) => {
  const { avatarUrlForUserId } = useUserAvatarUrls();

  return (
    <Tippy content={fullName}>
      <div>
        <img
          src={avatarUrlForUserId(id)}
          className="block size-6 rounded-full border"
          style={{ borderColor: "#3F3F4633" }}
        />
      </div>
    </Tippy>
  );
};

const ActivitiesTooltipContent = ({
  group,
  filteredIntervalIndex,
  overflow,
}: {
  group: Omit<UserActivityIntervalMetrics, "userId">;
  filteredIntervalIndex: number;
  overflow: boolean;
}) => {
  const intervalIndexOffset =
    (60 / INTERVAL_LENGTH_MINUTES) * START_DISPLAY_HOUR;
  const intervalIndex = filteredIntervalIndex + intervalIndexOffset;
  const intervalStartDt = DateTime.fromISO(group.intervalStart, {
    zone: "utc",
  }).plus({ minutes: INTERVAL_LENGTH_MINUTES * intervalIndex });
  const intervalEndDt = intervalStartDt.plus({
    minutes: INTERVAL_LENGTH_MINUTES,
  });

  const dayStr = intervalStartDt.toFormat("ccc LLL d");
  const startTime = intervalStartDt.toFormat("h:mma").toLowerCase();
  const endTime = intervalEndDt.toFormat("h:mma").toLowerCase();

  const timeRangeStr =
    startTime.slice(-2) === endTime.slice(-2)
      ? startTime.slice(0, -2) + " - " + endTime
      : startTime + " - " + endTime;

  const active =
    overflow || group.intervalActiveStatuses[Math.ceil(intervalIndex)];

  return (
    <div style={{ width: 240 }}>
      <div className="border-b border-inherit px-4 py-2 flex justify-between items-center">
        <span className="font-medium text-gray-900 truncate leading-6">
          {dayStr}
        </span>
        <span className="font-medium text-gray-900 whitespace-nowrap leading-6">
          {overflow ? "*" + timeRangeStr : timeRangeStr}
        </span>
      </div>
      <div className="space-y-1 px-4 py-2">
        <div className="flex items-center justify-between space-x-8 w-full">
          <div className="flex items-center space-x-2">
            <ChartUserAvatar user={group.user} />
            <span className="whitespace-nowrap text-gray-700">
              {group.user.firstName} {group.user.lastName.at(0)}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <span
              className={classNames(
                "font-medium",
                active ? "text-green-700" : "text-zinc-500"
              )}
            >
              {active ? "Active" : "Inactive"}
            </span>
            <span
              className={classNames(
                "size-2 border rounded-full",
                active
                  ? "bg-green-500 border-green-200"
                  : "bg-zinc-500 border-zinc-200"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HoverRect = ({
  weekday,
  rowIndex,
  columnIndex,
  cellWidth,
  cellHeight,
  className,
  onMouseMove,
}: {
  rowIndex: number;
  columnIndex: number;
  cellWidth: number;
  cellHeight: number;
  weekday: number;
  className?: string;
  onMouseMove?: (event: React.MouseEvent) => void;
}) => {
  const x = columnIndex * (cellWidth + CELL_SPACING_X);
  const y = rowIndex * (cellHeight + CELL_SPACING_Y);

  const isSunday = weekday === 7;
  const isSaturday = weekday === 6;
  const isWeekend = isSaturday || isSunday;

  return (
    <rect
      x={isSunday ? x + cellWidth / 2 : x}
      y={y}
      width={isWeekend ? cellWidth / 2 : cellWidth}
      height={cellHeight}
      className={className}
      onMouseMove={onMouseMove}
    />
  );
};

export default ROIMetrics;

import Select from "../../components/Select";
import useFeatureFlag, {
  FeatureFlag,
} from "../../feature-flags/useFeatureFlag";
import usePeriodOptions, { PeriodOption } from "./usePeriodOptions";
import TriageInsightsSection from "./TriageInsightsSection";
import DirectBillSection from "./DirectBillSection";
import TriageDetailSection from "./TriageDetailSection";
import { useLoggedInUser } from "../../components/LoggedInUserProvider";
import { UserRole } from "@comulate/graphql-types";
import ROIMetrics from "./ROIMetrics";

export const Dashboard = () => {
  const { user } = useLoggedInUser();

  // Whether to show triage analytics section
  const triageAnalyticsEnabled = useFeatureFlag<boolean>(
    FeatureFlag.ENABLE_TRIAGE_ANALYTICS
  );
  // Whether to show admin ROI analytics section
  const roiAnalyticsEnabled = useFeatureFlag<boolean>(
    FeatureFlag.ENABLE_ROI_ANALYTICS
  );

  const [selectedPeriod, setSelectedPeriod, allPeriodsOptions] =
    usePeriodOptions();

  return (
    <>
      <div className="flex flex-col h-full w-full py-7 overflow-y-scroll">
        <div
          className="max-w-8xl mx-auto w-full space-y-10"
          style={{
            paddingLeft: "calc(16px + (100vw - 100%))",
            paddingRight: 16,
          }}
        >
          <div className="space-y-5">
            <div className="flex justify-between">
              <div className="text-2xl leading-8 font-medium text-zinc-900">
                Direct Bill
              </div>
              <div>
                <Select
                  size="xs"
                  mainClasses="w-30 h-6"
                  menuClasses="w-48 right-0"
                  anchor="bottom end"
                  options={allPeriodsOptions}
                  selectedOption={selectedPeriod}
                  onChange={(option: PeriodOption) => setSelectedPeriod(option)}
                />
              </div>
            </div>
            <DirectBillSection
              selectedPeriodId={selectedPeriod ? selectedPeriod.id : null}
            />
          </div>
          {roiAnalyticsEnabled && user?.role === UserRole.ADMIN && (
            <ROIMetrics
              selectedPeriodId={selectedPeriod ? selectedPeriod.id : null}
            />
          )}
          {triageAnalyticsEnabled ? (
            <div className="flex flex-col">
              <TriageInsightsSection />
              <TriageDetailSection />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
